import { gql } from "@apollo/client"

const RUN_FIELDS = gql`
  fragment RunFields on Run {
    id
    rows(page: 0, size: 10) {
      data {
        id
        data
      }
    }
    loading
    data_length
    created_at
    cancelled_at
    positive_feedback_at
    negative_feedback_at
    error
  }
`

export default RUN_FIELDS

export const QUESTION_RUN_FIELDS = gql`
  fragment QuestionRunFields on Run {
    id
    rows(page: 0, size: 10) {
      data {
        id
        data
      }
    }
    loading
    data_length
    error
  }
`

// Used in dashboards for plots that load by pane
export const PLOT_RUN_FIELDS = gql`
  fragment PlotRunFields on Run {
    id
    all_rows: rows(size: 1000) {
      data {
        id
        data
      }
    }
    loading
    data_length
    error
  }
`
