import React from "react"
import { gql, useSubscription } from "@apollo/client"
import { Outlet, useParams } from "react-router-dom"
import { PLOT_RUN_FIELDS } from "fragments/run"
import { RunUpdated, RunUpdatedVariables } from "./__generated__/RunUpdated"

export const RUN_SUBSCRIPTION = gql`
  ${PLOT_RUN_FIELDS}
  subscription RunUpdated($workspaceId: ID!) {
    runUpdated(workspaceId: $workspaceId) {
      ...PlotRunFields
    }
  }
`

const SubscriptionProvider: React.FC = () => {
  const { workspaceId } = useParams()

  useSubscription<RunUpdated, RunUpdatedVariables>(RUN_SUBSCRIPTION, {
    variables: {
      workspaceId: workspaceId ?? "",
    },
  })

  return <Outlet />
}

export default SubscriptionProvider
