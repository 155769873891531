import React from "react"
import { Navigate, Outlet, useLocation } from "react-router-dom"
import NotFound from "components/layout/NotFound"
import useAuth from "./useAuth"

const AdminRoute: React.FC = () => {
  const { loggedIn, user } = useAuth()
  const location = useLocation()

  if (!loggedIn) return <Navigate to="/login" state={{ redirect: location }} />

  if (!user?.admin) return <NotFound />

  return <Outlet />
}

export default AdminRoute
