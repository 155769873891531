import React, { ReactNode } from "react"
import { ApolloProvider } from "@apollo/client"
import { useLocalStorage } from "@uidotdev/usehooks"
import make_client from "client"
import AuthProvider from "./AuthProvider"

type BackendProviderProps = {
  children: ReactNode
}

export interface User {
  id: string
  admin?: boolean
}

const BackendProvider: React.FC<BackendProviderProps> = ({ children }) => {
  const [loggedIn, setLoggedIn] = useLocalStorage("loggedIn", false)
  const [user, setUser] = useLocalStorage<User | null>("user", null)

  const logoutUser = () => {
    setLoggedIn(false)
    setUser(null)
  }

  const client = make_client(logoutUser)

  return (
    <ApolloProvider client={client}>
      <AuthProvider
        loggedIn={loggedIn}
        setLoggedIn={setLoggedIn}
        user={user}
        setUser={setUser}
        client={client}
      >
        {children}
      </AuthProvider>
    </ApolloProvider>
  )
}

export default BackendProvider
